import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import Section from "./Section";
import AppointmentCountCard from "./components/AppointmentCountCard";
import TodaysAppointmentGraph from './components/TodaysAppointmentGraph';
import AdminService from "../../services/admin.service";

const DashboardEcommerce = () => {
  const [appointmentsCounts, setAppointmentsCounts] = useState([]);
  
  const getAppointmentsCounts = async () => {
    const appointmentsCountsRes = await AdminService.getAppointmentsCounts();
    if (appointmentsCountsRes.statusCode === 200) {
        setAppointmentsCounts(appointmentsCountsRes.data);
    }
  }

  useEffect(() => {
    getAppointmentsCounts();
  }, []);

  document.title ="Dashboard | VMS";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="mh-50">
            <Col>
              <div className="h-100">
                <Row>
                  <AppointmentCountCard appointmentsCounts={appointmentsCounts} />
                </Row>
                <Row>
                  <Col xl={12}>
                    <TodaysAppointmentGraph />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardEcommerce;
