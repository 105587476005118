import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import AddEditUserModal from "./modals/AddEditUserModal";
import UserService from "../../services/user.service";
import RoleService from '../../services/role.service';
import DeleteUserModal from "./modals/DeleteUserModal";

const Users = () => {
  let SrNo = 0;
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);

  const getUsers = async () => {
    const getUsersRes = await UserService.getUsers();
    if (getUsersRes.statusCode === 200) {
      setUsers(getUsersRes.data);
    }
  };

  const getRoles = async () => {
    const getRolesRes = await RoleService.getRoles();
    if (getRolesRes.statusCode === 200) {
      setRoles(getRolesRes.data);
    }
  };

  useEffect(() => {
    getUsers();
    getRoles();
  }, [])

  document.title = "List Users";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="List Users" pageTitle="Users" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Add, Edit & Remove Users</h4>
                </CardHeader>

                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <AddEditUserModal buttonLabel={'Add'} isEdit={false} getUsers={getUsers} roles={roles} />
                      </Col>
                      <Col className="col-sm">
                      </Col>
                    </Row>

                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="customerTable"
                      >
                        <thead className="table-light">
                          <tr>
                            <th className="sort" data-sort="customer_name">
                              Sr No
                            </th>
                            <th className="sort" data-sort="customer_name">
                              Fullname
                            </th>
                            <th className="sort" data-sort="customer_name">
                              Email
                            </th>
                            <th className="sort" data-sort="customer_name">
                              Role
                            </th>
                            <th className="sort" data-sort="action">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all">
                          {users &&
                            users.length > 0 &&
                            users.map((user, index) => {
                              SrNo += 1;
                              return (
                                <tr key={index}>
                                  <td className="customer_name">{SrNo}</td>
                                  <td className="customer_name">
                                    {`${user.firstName} ${user.lastName}`}
                                  </td>
                                  <td className="customer_name">
                                    {user.email}
                                  </td>
                                  <td className="customer_name">
                                    {user.Role.title}
                                  </td>
                                  <td>
                                    <div className="d-flex gap-2">
                                      <AddEditUserModal buttonLabel='Edit' isEdit={true} user={user} getUsers={getUsers} roles={roles} />
                                      <DeleteUserModal user={user} getUsers={getUsers} />
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                      <div className="noresult" style={{ display: "none" }}>
                        <div className="text-center">
                          <lord-icon
                            src="https://cdn.lordicon.com/msoeawqm.json"
                            trigger="loop"
                            colors="primary:#121331,secondary:#08a88a"
                            style={{ width: "75px", height: "75px" }}
                          ></lord-icon>
                          <h5 className="mt-2">Sorry! No Result Found</h5>
                          <p className="text-muted mb-0">
                            We've searched more than 150+ Orders We did not find
                            any orders for you search.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Users;
