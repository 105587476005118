import { toast } from "react-toastify";
import { postReqWithoutToken } from ".";

const AUTH_URL = '/auth';

const signUp = async (payload) => {
  try {
    const signUpPath = `${AUTH_URL}/sign-up`;
    return await postReqWithoutToken(signUpPath, payload)
  } catch (error) {
    toast.error('Something went wrong, try again');
    console.log("Error from signUp", error);
  }
};

const logIn = async (payload) => {
  try {
    const loginPath = `${AUTH_URL}/sign-in`;
    return await postReqWithoutToken(loginPath, payload);
  } catch (error) {
    toast.error('Wrong password')
  }
};

const AuthService = {
  signUp,
  logIn,
};

export default AuthService;
