import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import UserService from "../../../services/user.service";

const AddEditUserModal = ({ buttonLabel, isEdit, user, getUsers, roles }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userData, setUserData] = useState({
    firstName: user?.firstName ? user.firstName : '',
    lastName: user?.lastName ? user.lastName : '',
    roleId: user?.roleId ? user.roleId : 2,
  });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const addEditUserHandler = async (e) => {
    e.preventDefault()
    if (isEdit) {
      const userUpdateRes = await UserService.updateUser(user.id, userData);
      if (userUpdateRes.statusCode === 200) {
        toast.success("User updated successfully!");
        setIsModalOpen(!isModalOpen);
      }
    } else {
      const userAddRes = await UserService.addUser(userData);
      if (userAddRes.statusCode === 200) {
        toast.success("User added successfully!");
        setIsModalOpen(!isModalOpen);
      }
    }
    getUsers();
  };

  return (
    <>
      {isEdit ? (
        <div className="edit">
          <button
            className="btn btn-sm btn-success edit-item-btn"
            onClick={() => setIsModalOpen(!isModalOpen)}
          >
            Edit
          </button>
        </div>
      ) : (
        <div>
          <Button
            color="success"
            className="add-btn me-1"
            onClick={() => setIsModalOpen(!isModalOpen)}
            id="create-btn"
          >
            <i className="ri-add-line align-bottom me-1"></i> {buttonLabel}
          </Button>
        </div>
      )}
      <Modal
        isOpen={isModalOpen}
        toggle={() => {
          toggleModal();
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            toggleModal();
          }}
        >
          {`${buttonLabel} User`}
        </ModalHeader>
        <form className="tablelist-form" onSubmit={addEditUserHandler}>
          <ModalBody>
            <div className="mb-3">
              <label htmlFor="firstName" className="form-label">
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                className="form-control"
                placeholder="Enter First Name"
                required
                defaultValue={user ? user.firstName : ''}
                onChange={(e) => {
                  setUserData({
                    ...userData,
                    firstName: e.target.value,
                  });
                }}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                className="form-control"
                placeholder="Enter Last Name"
                required
                defaultValue={user ? user.lastName : ''}
                onChange={(e) => {
                  setUserData({
                    ...userData,
                    lastName: e.target.value,
                  });
                }}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="roleId" className="form-label">
                Role
              </label>
              <select
                className="form-control"
                data-trigger
                name="roleId"
                id="roleId"
                required
                onChange={(e) => {
                  setUserData({
                    ...userData,
                    roleId: e.target.value,
                  });
                }}
              >
                <option disabled hidden selected>
                  Select Role
                </option>
                {
                  roles && roles.length && roles.map((role) => {
                    return (
                      <React.Fragment key={role.id}>
                        {
                          user?.Role?.id === role?.id ?
                            <option selected value={role.id}>{role.title}</option>
                          :
                            <option value={role.id}>{role.title}</option>
                        }
                      </React.Fragment>
                    )
                  })
                }
              </select>
            </div>

            {isEdit ? (
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="form-control"
                  placeholder="Enter Email"
                  disabled
                  defaultValue={user?.email}
                />
              </div>
            ) : (
              <>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    placeholder="Enter Email"
                    required
                    onChange={(e) => {
                      setUserData({
                        ...userData,
                        email: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    placeholder="Enter Password"
                    required
                    pattern=".{8,}"
                    title="Minimum 8 characters are required"
                    onChange={(e) => {
                      setUserData({
                        ...userData,
                        password: e.target.value,
                      });
                    }}
                  />
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setIsModalOpen(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {buttonLabel}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default AddEditUserModal;
