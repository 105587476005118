import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import logoLight from '../../assets/images/logo-light.png'
import AuthService from '../../services/auth.service';
import { Link, useNavigate } from "react-router-dom";
import withRouter from '../../Components/Common/withRouter';
import { toast } from 'react-toastify';

const Login = (props) => {
    const [credentials, setCredentials] = useState({
        email: '',
        password: '',
    });
    const [passwordShow, setPasswordShow] = useState(false);

    const navigate = useNavigate();

    const signIn = async () => {
        const userLogInRes = await AuthService.logIn(credentials);
        if (userLogInRes?.statusCode === 200) {
            localStorage.setItem('accessToken', userLogInRes.data.accessToken);
            localStorage.setItem('user', JSON.stringify(userLogInRes.data.user));
            toast.success(`Logged in successfully`)
            navigate('/dashboard');
        }
    };

    document.title = "SignIn | Appointment System";
    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <Link to="/" className="d-inline-block auth-logo">
                                        <img src={logoLight} alt="" width='110' />
                                    </Link>
                                    <p className="mt-3 fs-15 fw-medium">Appointment System</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Welcome Back !</h5>
                                            <p className="text-muted">Sign in to continue to Appointment System.</p>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    signIn();
                                                }}
                                            >
                                                <div className="mb-3">
                                                    <Label htmlFor="email" className="form-label">Email</Label>
                                                    <Input
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter email"
                                                        type="email"
                                                        onChange={(e) => {
                                                            setCredentials({
                                                                ...credentials,
                                                                email: e.target.value,
                                                            })
                                                        }}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="password-input">Password</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            name="password"
                                                            type={passwordShow ? "text" : "password"}
                                                            className="form-control pe-5"
                                                            placeholder="Enter Password"
                                                            onChange={(e) => {
                                                            setCredentials({
                                                                ...credentials,
                                                                password: e.target.value,
                                                            })
                                                        }}
                                                        />
                                                        <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}>
                                                            <i className="ri-eye-fill align-middle"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="form-check">
                                                    <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                    <Label className="form-check-label" htmlFor="auth-remember-check">Remember me</Label>
                                                </div>

                                                <div className="mt-4">
                                                    <Button color="success" className="btn btn-success w-100" type="submit">
                                                        Sign In
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(Login);