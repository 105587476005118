import React, { Component } from "react";
import Leaflet from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";

Leaflet.Icon.Default.imagePath = "../node_modules/leaflet";

delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
});

export default class Map extends Component {
  render() {
    const position = [this.props?.position?.lat, this.props?.position?.lng];
    return (
      <>
        {position[0] === undefined || position[1] === undefined ? (
          <h1>Failed to get location!</h1>
        ) : (
          <MapContainer center={position} zoom={13} style={{ height: "100%" }}>
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position}>
              <Popup>Hello World!</Popup>
            </Marker>
          </MapContainer>
        )}
      </>
    );
  }
}

// import React, { useEffect, useState } from "react";
// import Leaflet from "leaflet";
// import "leaflet/dist/leaflet.css";
// import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

// Leaflet.Icon.Default.imagePath = "../node_modules/leaflet";

// delete Leaflet.Icon.Default.prototype._getIconUrl;

// Leaflet.Icon.Default.mergeOptions({
//   iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
//   shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
// });

// const Map = () => {
//   const position = useState({
//     lat: 30,
//     lng: 30,
//     zoom: 13,
//   });
//   const mapStyles = {
//     width: "100%",
//     height: "100vh",
//   };
//   const layer = Leaflet.tileLayer(
//     `https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png`,
//     {
//       attribution:
//         '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
//     }
//   );

//   const mapParams = {
//     center: [0, 0],
//     zoom: 0,
//     layers: [layer],
//   };

//   // This useEffect hook runs when the component is first mounted,
//   // similar to componentDidMount() lifecycle method of class-based
//   // components:
//   useEffect(() => {
//     Leaflet.map("map", mapParams);
//   }, []);

//   return (
//     <div>
//       {/* <div id="map" style={mapStyles}> */}
//       <div id="map">

//         <MapContainer
//           center={position}
//           zoom={position.zoom}
//           style={{ height: "100vh" }}
//         >
//           <TileLayer
//             attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
//             url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           />
//           <Marker position={position}>
//             <Popup>Hello World!</Popup>
//           </Marker>
//         </MapContainer>
//       </div>
//       {/* </div> */}
//     </div>
//   );
// };

// export default Map;
