import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import Loader from "../../Components/Common/Loader";
import VisitorService from "../../services/visitor.service";
//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";

//Import maps
import MapWithPopup from "../Maps/LeafletMaps/MapsLeaflet/MapWithPopup";
import Map from "./Map/Map";
import AdminService from "../../services/admin.service";

const ViewLocation = () => {
  document.title = "View Location";

  const { visitorId } = useParams();

  const [position, setPosition] = useState({
    
  });

  // Function to make setTimeOut await
  const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

  const requestLocation = async () => {
    await VisitorService.requestLocation(visitorId);
    await wait(10000);
    const getVisitorByIdRes = await AdminService.getVisitorById(visitorId);
    const location = {
      lat: getVisitorByIdRes.data.lat,
      lng: getVisitorByIdRes.data.lng,
    }
    setPosition(location);
  }

  useEffect(() => {
    requestLocation();
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Leaflet" pageTitle="Maps" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Working with Popups</h4>
                </CardHeader>
                <CardBody>
                  <div id="leaflet-map-popup" className="leaflet-map">
                    { JSON.stringify(position) === '{}' ? <Loader /> : <Map position={position} /> }
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewLocation;
