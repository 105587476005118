import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
//Import Flatepicker
import Flatpickr from "react-flatpickr";
import AppointmentService from "../../services/appointment.service";
import PTMService from "../../services/personToMeet.service";
import { toast } from "react-toastify";

const Appointments = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };

  const [editModalToggle, setEditModalToggle] = useState(false);
  const tog_edit = () => {
    setEditModalToggle(!editModalToggle);
  };

  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  const [appointments, setAppointments] = useState([]);
  const [newAppointment, setNewAppointment] = useState({
    fullName: "",
    mobileNo: "",
    dateOfAppointment: "",
    from: "",
    to: "",
  });

  const [editAppointment, setEditAppointment] = useState({
    fullName: "",
    mobileNo: "",
    dateOfAppointment: "",
    from: "",
    to: "",
    officeNo: "",
  });
  const [editAppointmentId, setEditAppointmentId] = useState();

  const [deleteAppointmentId, setDeleteAppointmentId] = useState();

  const [personToMeet, setPersonToMeet] = useState([]);
  const getPersonToMeet = async () => {
    const peopleToMeetRes = await PTMService.getPersonToMeet();
    if (peopleToMeetRes.statusCode === 200) {
      setPersonToMeet(peopleToMeetRes.data);
    }
  };

  const getAppointments = async () => {
    const getAppointmentRes = await AppointmentService.getAppointments();
    if (getAppointmentRes.statusCode === 200) {
      setAppointments(getAppointmentRes.data);
    }
  };

  const addAppointmentHandler = async (e) => {
    e.preventDefault();
    const addAppointmentRes = await AppointmentService.addAppointment(
      newAppointment
    );
    if (addAppointmentRes.statusCode === 200) {
      getAppointments();
      toast.success("Appointment added successfully");
    }
    tog_list();
    setNewAppointment({});
  };

  const editClickHandler = async (id) => {
    const getAppointmentByIdRes = await AppointmentService.getAppointmentById(
      id
    );
    if (getAppointmentByIdRes.statusCode === 200) {
      setEditAppointmentId(id);
      setEditAppointment({
        fullName: getAppointmentByIdRes.data.fullName,
        mobileNo: getAppointmentByIdRes.data.mobileNo,
        dateOfAppointment: getAppointmentByIdRes.data.dateOfAppointment,
        from: getAppointmentByIdRes.data.from,
        to: getAppointmentByIdRes.data.to,
        officeNo: getAppointmentByIdRes.data.officeNo,
        personToMeetId: getAppointmentByIdRes.data.personToMeetId,
        status: getAppointmentByIdRes.data.status,
      });
      tog_edit();
    }
  };
  const editAppointmentHandler = async (e) => {
    e.preventDefault();
    const editAppointmentRes = await AppointmentService.updateAppointment(
      editAppointmentId,
      editAppointment
    );
    if (editAppointmentRes.statusCode === 200) {
      getAppointments();
      toast.success("Appointment updated successfully");
    }
    tog_edit();
  };

  const deleteAppointment = async () => {
    const deleteAppointmentRes = await AppointmentService.deleteAppointment(
      deleteAppointmentId
    );
    if (deleteAppointmentRes.statusCode === 200) {
      getAppointments();
      toast.success("Appointment deleted successfully");
    }
    tog_delete();
  };

  useEffect(() => {
    getAppointments();
    getPersonToMeet();
  }, []);

  document.title = "List Appointments";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="List Appointments" pageTitle="Appointments" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">
                    Add, Edit & Remove Appointments
                  </h4>
                </CardHeader>

                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={() => tog_list()}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add
                          </Button>
                        </div>
                      </Col>
                      <Col className="col-sm">
                        {/* <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Search..."
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div> */}
                      </Col>
                    </Row>

                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="customerTable"
                      >
                        <thead className="table-light">
                          <tr>
                            <th className="sort" data-sort="customer_name">
                              Full Name
                            </th>
                            <th className="sort" data-sort="email">
                              Mobile Number
                            </th>
                            <th className="sort" data-sort="phone">
                              Person To Meet
                            </th>
                            <th className="sort" data-sort="date">
                              Date
                            </th>
                            <th className="sort" data-sort="date">
                              Time Slot
                            </th>
                            <th className="sort" data-sort="date">
                              Office No
                            </th>
                            <th className="sort" data-sort="status">
                              Status
                            </th>
                            <th className="sort" data-sort="action">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all">
                          {appointments &&
                            appointments.map((appointment, index) => {
                              const statusBadgeClass =
                                appointment.status === "completed"
                                  ? "badge-soft-success"
                                  : appointment.status === "pending"
                                  ? "badge-soft-info"
                                  : "badge-soft-danger";
                              return (
                                <tr key={index}>
                                  <td className="customer_name">
                                    {appointment.fullName}
                                  </td>
                                  <td className="phone">
                                    {appointment.mobileNo}
                                  </td>
                                  <td className="ptm-name">
                                    {appointment.PersonToMeet.name}
                                  </td>
                                  <td className="date">
                                    {appointment?.dateOfAppointment === null
                                      ? "Not selected"
                                      : appointment?.dateOfAppointment}
                                  </td>
                                  <td className="time-slot">
                                    {appointment?.from === null ||
                                    appointment?.to === null
                                      ? "Not selected"
                                      : `${appointment?.from?.substring(
                                          0,
                                          5
                                        )} - ${appointment?.to?.substring(
                                          0,
                                          5
                                        )}`}
                                  </td>
                                  <td className="office-no">
                                    {appointment?.officeNo === null
                                      ? "Not provided"
                                      : appointment?.officeNo}
                                  </td>
                                  <td className="status">
                                    <span
                                      className={`badge ${statusBadgeClass} text-uppercase`}
                                    >
                                      {appointment.status}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="d-flex gap-2">
                                      <div className="edit">
                                        <button
                                          className="btn btn-sm btn-success edit-item-btn"
                                          onClick={() => {
                                            editClickHandler(appointment.id);
                                          }}
                                        >
                                          Edit
                                        </button>
                                      </div>
                                      {user.role.title === "admin" ? (
                                        <div className="remove">
                                          <button
                                            className="btn btn-sm btn-danger remove-item-btn"
                                            onClick={(e) => {
                                              setDeleteAppointmentId(
                                                appointment.id
                                              );
                                              tog_delete();
                                            }}
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                      <div className="noresult" style={{ display: "none" }}>
                        <div className="text-center">
                          <lord-icon
                            src="https://cdn.lordicon.com/msoeawqm.json"
                            trigger="loop"
                            colors="primary:#121331,secondary:#08a88a"
                            style={{ width: "75px", height: "75px" }}
                          ></lord-icon>
                          <h5 className="mt-2">Sorry! No Result Found</h5>
                          <p className="text-muted mb-0">
                            We've searched more than 150+ Orders We did not find
                            any orders for you search.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Add Modal */}
      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            tog_list();
          }}
        >
          {" "}
          Add Appointment{" "}
        </ModalHeader>
        <form className="tablelist-form" onSubmit={addAppointmentHandler}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>

            <div className="mb-3">
              <label htmlFor="customername-field" className="form-label">
                Full Name
              </label>
              <input
                type="text"
                id="customername-field"
                className="form-control"
                placeholder="Enter Full Name"
                required
                onChange={(e) => {
                  setNewAppointment({
                    ...newAppointment,
                    fullName: e.target.value,
                  });
                }}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="phone-field" className="form-label">
                Mobile Number
              </label>
              <input
                type="text"
                id="phone-field"
                className="form-control"
                placeholder="Enter Mobile no."
                required
                onChange={(e) => {
                  setNewAppointment({
                    ...newAppointment,
                    mobileNo: e.target.value,
                  });
                }}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="phone-field" className="form-label">
                Person To Meet
              </label>
              <select
                className="form-control"
                data-trigger
                name="ptm-field"
                id="ptm-field"
                required
                onChange={(e) => {
                  setNewAppointment({
                    ...newAppointment,
                    personToMeetId: e.target.value,
                  });
                }}
              >
                <option selected disabled hidden value={""}>
                  Select Person To Meet
                </option>
                {personToMeet.length &&
                  personToMeet.map((person, index) => {
                    return (
                      <option key={index} value={person.id}>
                        {person.name}
                      </option>
                    );
                  })}
              </select>
            </div>

            {user.role.title === "admin" ? (
              <>
                <div className="mb-3">
                  <label htmlFor="doa-field" className="form-label">
                    Date Of Appointment
                  </label>
                  <Flatpickr
                    className="form-control"
                    options={{
                      dateFormat: "d M, Y",
                    }}
                    onChange={(e) => {
                      setNewAppointment({
                        ...newAppointment,
                        dateOfAppointment: e[0],
                      });
                    }}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="phone-field" className="form-label">
                    From
                  </label>
                  <Flatpickr
                    className="form-control"
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                    }}
                    onChange={(e) => {
                      const fromHour = e[0].getHours();
                      const fromMin = e[0].getMinutes();
                      setNewAppointment({
                        ...newAppointment,
                        from: `${fromHour}:${fromMin}:00`,
                      });
                    }}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="phone-field" className="form-label">
                    Till
                  </label>
                  <Flatpickr
                    className="form-control"
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                    }}
                    onChange={(e) => {
                      const toHour = e[0].getHours();
                      const toMin = e[0].getMinutes();
                      setNewAppointment({
                        ...newAppointment,
                        to: `${toHour}:${toMin}:00`,
                      });
                    }}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="customername-field" className="form-label">
                    Office No.
                  </label>
                  <input
                    type="text"
                    id="customername-field"
                    className="form-control"
                    placeholder="Enter Office Number"
                    required
                    onChange={(e) => {
                      setNewAppointment({
                        ...newAppointment,
                        officeNo: e.target.value,
                      });
                    }}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                Add
              </button>
              {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Edit Modal */}
      <Modal
        isOpen={editModalToggle}
        toggle={() => {
          tog_edit();
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            tog_edit();
          }}
        >
          {" "}
          Edit Appointment{" "}
        </ModalHeader>
        <form className="tablelist-form" onSubmit={editAppointmentHandler}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>

            <div className="mb-3">
              <label htmlFor="customername-field" className="form-label">
                Full Name
              </label>
              <input
                type="text"
                id="customername-field"
                className="form-control"
                placeholder="Enter Full Name"
                required
                defaultValue={editAppointment.fullName}
                onChange={(e) => {
                  setEditAppointment({
                    ...editAppointment,
                    fullName: e.target.value,
                  });
                }}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="phone-field" className="form-label">
                Mobile Number
              </label>
              <input
                type="text"
                id="phone-field"
                className="form-control"
                placeholder="Enter Mobile no."
                required
                defaultValue={editAppointment.mobileNo}
                onChange={(e) => {
                  setEditAppointment({
                    ...editAppointment,
                    mobileNo: e.target.value,
                  });
                }}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="phone-field" className="form-label">
                Person To Meet
              </label>
              <select
                className="form-control"
                data-trigger
                name="ptm-field"
                id="ptm-field"
                required
                onChange={(e) => {
                  setEditAppointment({
                    ...editAppointment,
                    personToMeetId: e.target.value,
                  });
                }}
              >
                {personToMeet &&
                  personToMeet.length > 0 &&
                  personToMeet.map((person, index) => {
                    return person.id === editAppointment.personToMeetId ? (
                      <option selected key={index} value={person.id}>
                        {person.name}
                      </option>
                    ) : (
                      <option key={index} value={person.id}>
                        {person.name}
                      </option>
                    );
                  })}
              </select>
            </div>

            {user.role.title === "admin" ? (
              <>
                <div className="mb-3">
                  <label htmlFor="doa-field" className="form-label">
                    Date Of Appointment
                  </label>
                  <Flatpickr
                    className="form-control"
                    options={{
                      dateFormat: "d M, Y",
                    }}
                    defaultValue={editAppointment?.dateOfAppointment}
                    onChange={(e) => {
                      setEditAppointment({
                        ...editAppointment,
                        dateOfAppointment: e[0],
                      });
                    }}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="phone-field" className="form-label">
                    From
                  </label>
                  <Flatpickr
                    className="form-control"
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                    }}
                    defaultValue={editAppointment?.from}
                    onChange={(e) => {
                      const fromHour = e[0].getHours();
                      const fromMin = e[0].getMinutes();
                      setEditAppointment({
                        ...editAppointment,
                        from: `${fromHour}:${fromMin}:00`,
                      });
                    }}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="phone-field" className="form-label">
                    Till
                  </label>
                  <Flatpickr
                    className="form-control"
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                    }}
                    defaultValue={editAppointment?.to}
                    onChange={(e) => {
                      const toHour = e[0].getHours();
                      const toMin = e[0].getMinutes();
                      setEditAppointment({
                        ...editAppointment,
                        to: `${toHour}:${toMin}:00`,
                      });
                    }}
                  />
                </div>

                <div>
                  <label htmlFor="status-field" className="form-label">
                    Status
                  </label>
                  {editAppointment.status === "completed" ? (
                    <select
                      className="form-control"
                      data-trigger
                      name="status-field"
                      id="status-field"
                      required
                      disabled
                    >
                      <option selected value="completed">
                        Completed
                      </option>
                    </select>
                  ) : (
                    <select
                      className="form-control"
                      data-trigger
                      name="status-field"
                      id="status-field"
                      required
                      onChange={(e) => {
                        setEditAppointment({
                          ...editAppointment,
                          status: e.target.value,
                        });
                      }}
                    >
                      {editAppointment.status === "approved" ? (
                        <option selected value="approved">
                          Approved
                        </option>
                      ) : (
                        <option value="approved">Approved</option>
                      )}
                      {editAppointment.status === "pending" ? (
                        <option selected value="pending">
                          Pending
                        </option>
                      ) : (
                        <option value="pending">Pending</option>
                      )}
                      {editAppointment.status === "rejected" ? (
                        <option selected value="rejected">
                          Rejected
                        </option>
                      ) : (
                        <option value="rejected">Rejected</option>
                      )}
                    </select>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="customername-field" className="form-label">
                    Office No.
                  </label>
                  <input
                    type="text"
                    id="customername-field"
                    className="form-control"
                    placeholder="Enter Office Number"
                    required
                    defaultValue={editAppointment?.officeNo}
                    onChange={(e) => {
                      setEditAppointment({
                        ...editAppointment,
                        officeNo: e.target.value,
                      });
                    }}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setEditModalToggle(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                Save
              </button>
              {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            tog_delete();
          }}
        ></ModalHeader>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Appointment ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => {
                deleteAppointment();
              }}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Appointments;
