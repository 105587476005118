import { toast } from "react-toastify";
import { getRequest } from ".";

const VISITORS_URL = "/visitor";

const getVisitors = async () => {
  try {
    const visitorsPath = `${VISITORS_URL}`;
    return await getRequest(visitorsPath);
  } catch (error) {
    toast.error('Something went wrong, try again');
    console.log("Error from getVisitors", error);
  }
};

const requestLocation = async (id) => {
  try {
    const visitorPath = `${VISITORS_URL}/req-location/${id}`;
    return await getRequest(visitorPath);
  } catch (error) {
    toast.error('Something went wrong, try again');
    console.log("Error from requestLocation", error);
  }
}

const VisitorService = {
  getVisitors,
  requestLocation,
};

export default VisitorService;
