import { toast } from "react-toastify";
import { getRequest } from ".";

const ADMIN_URL = "/admin";

const getVisitorById = async (id) => {
  try {
    const adminPath = `${ADMIN_URL}/visitor/${id}`;
    return await getRequest(`${adminPath}`);
  } catch (error) {
    toast.error("Something went wrong, try again");
    console.log("Error from getVisitorById", error);
  }
};

const getAppointmentsCounts = async () => {
  try {
    const adminPath = `${ADMIN_URL}/appointments-counts`;
    return await getRequest(`${adminPath}`);
  } catch (error) {
    toast.error("Something went wrong, try again");
    console.log("Error from getTodayAppointments", error);
  }
};

const getTodaysAppointments = async () => {
  try {
    const adminPath = `${ADMIN_URL}/today-appointments-counts`;
    return await getRequest(adminPath);
  } catch (error) {
    toast.error("Something went wrong, try again");
    console.log("Error from getTodayAppointments", error);
  }
}

const AdminService = {
  getVisitorById,
  getAppointmentsCounts,
  getTodaysAppointments,
};

export default AdminService;
