import { toast } from "react-toastify";
import { getRequest, postRequest, putRequest, deleteRequest } from ".";

const USERS_URL = "/user";

const getUsers = async () => {
  try {
    const usersPath = `${USERS_URL}`;
    return await getRequest(usersPath);
  } catch (error) {
    toast.error('Something went wrong, try again');
    console.log("Error from getUsers", error);
  }
};

const addUser = async (payload) => {
  try {
    const usersPath = `${USERS_URL}`;
    return await postRequest(usersPath, payload);
  } catch (error) {
    toast.error("Something went wrong, try again");
    console.log("error from addUser", error);
  }
};

const updateUser = async (id, payload) => {
  try {
    const usersPath = `${USERS_URL}/${id}`;
    return await putRequest(usersPath, payload);
  } catch (error) {
    toast.error("Something went wrong, try again");
    console.log("error from updateUser", error);
  }
};

const deleteUser = async (id) => {
  try {
    const usersPath = `${USERS_URL}/${id}`;
    return await deleteRequest(usersPath);
  } catch (error) {
    toast.error("Something went wrong, try again");
    console.log("error from deleteUser", error);
  }
};

const getUserById = async (id) => {
  try {
    const usersPath = `${USERS_URL}/${id}`;
    return await getRequest(usersPath);
  } catch (error) {
    toast.error("Something went wrong, try again");
    console.log("error from getUserById", error);
  }
};

const UserService = {
  getUsers,
  addUser,
  updateUser,
  deleteUser,
  getUserById,
};

export default UserService;
