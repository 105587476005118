import { toast } from "react-toastify";
import { getRequest, postRequest, putRequest, deleteRequest } from ".";

const PTM_URL = "/person-to-meet";

const getPersonToMeet = async () => {
  try {
    const ptmPath = `${PTM_URL}`;
    return await getRequest(ptmPath);
  } catch (error) {
    toast.error('Something went wrong, try again');
    console.log("Error from getPersonToMeet", error);
  }
};

const addPersonToMeet = async (payload) => {
  try {
    const ptmPath = `${PTM_URL}`;
    return await postRequest(ptmPath, payload);
  } catch (error) {
    toast.error("Something went wrong, try again");
    console.log("error from addPersonToMeet", error);
  }
};

const updatePersonToMeet = async (id, payload) => {
  try {
    const ptmPath = `${PTM_URL}/${id}`;
    return await putRequest(ptmPath, payload);
  } catch (error) {
    toast.error("Something went wrong, try again");
    console.log("error from updatePersonToMeet", error);
  }
};

const deletePersonToMeet = async (id) => {
  try {
    const ptmPath = `${PTM_URL}/${id}`;
    return await deleteRequest(ptmPath);
  } catch (error) {
    toast.error("Something went wrong, try again");
    console.log("error from deletePersonToMeet", error);
  }
};

const getPersonToMeetById = async (id) => {
  try {
    const ptmPath = `${PTM_URL}/${id}`;
    return await getRequest(ptmPath);
  } catch (error) {
    toast.error("Something went wrong, try again");
    console.log("error from getPersonToMeetById", error);
  }
};

const PTMService = {
  getPersonToMeet,
  addPersonToMeet,
  updatePersonToMeet,
  deletePersonToMeet,
  getPersonToMeetById,
};

export default PTMService;
