const getToken = () => {
    const token = localStorage.getItem('accessToken');
    return token;
};

const setToken = (accessToken) => {
    localStorage.setItem('accessToken', accessToken);
}

export {
    getToken,
    setToken,
};
