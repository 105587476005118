import { toast } from "react-toastify";
import { getRequest } from ".";

const ROLES_URL = "/role";

const getRoles = async () => {
  try {
    const rolesPath = `${ROLES_URL}`;
    return await getRequest(rolesPath);
  } catch (error) {
    toast.error('Something went wrong, try again');
    console.log("Error from getRoles", error);
  }
};

const RoleService = {
  getRoles,
};

export default RoleService;
