import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import UserService from "../../../services/user.service";
import { toast } from "react-toastify";

const DeleteUserModal = ({ user, getUsers }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const deleteUserHandler = async () => {
    const deleteUserRes = await UserService.deleteUser(user?.id);
    if (deleteUserRes.statusCode === 200) {
      toast.success('User deleted successfully!');
      toggleModal();
    }
    getUsers();
  };

  return (
    <>
      <div className="remove">
        <button
          className="btn btn-sm btn-danger remove-item-btn"
          onClick={() => {
            toggleModal();
          }}
        >
          Remove
        </button>
      </div>
      <Modal
        isOpen={isModalOpen}
        toggle={() => {
          toggleModal();
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            toggleModal();
          }}
        ></ModalHeader>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                {`Are you Sure You want to Remove ${user?.firstName} ${user?.lastName} ?`}
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setIsModalOpen(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => deleteUserHandler()}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default DeleteUserModal;
