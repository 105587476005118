import { toast } from "react-toastify";
import { getRequest, postRequest, putRequest, deleteRequest } from ".";

const APPOINTMENT_URL = "/appointment";

const getAppointments = async () => {
  try {
    const appointmentPath = `${APPOINTMENT_URL}`;
    return await getRequest(appointmentPath);
  } catch (error) {
    toast.error("Something went wrong, try again");
    console.log("Error from getAppointments", error);
  }
};

const addAppointment = async (payload) => {
  try {
    const appointmentPath = `${APPOINTMENT_URL}`;
    return await postRequest(appointmentPath, payload);
  } catch (error) {
    toast.error("Something went wrong, try again");
    console.log("error from addAppointment", error);
  }
};

const updateAppointment = async (id, payload) => {
  try {
    const appointmentPath = `${APPOINTMENT_URL}/${id}`;
    return await putRequest(appointmentPath, payload);
  } catch (error) {
    toast.error("Something went wrong, try again");
    console.log("error from updateAppointment", error);
  }
};

const deleteAppointment = async (id) => {
  try {
    const appointmentPath = `${APPOINTMENT_URL}/${id}`;
    return await deleteRequest(appointmentPath);
  } catch (error) {
    toast.error("Something went wrong, try again");
    console.log("error from deleteAppointment", error);
  }
};

const getAppointmentById = async (id) => {
  try {
    const appointmentPath = `${APPOINTMENT_URL}/${id}`;
    return await getRequest(appointmentPath);
  } catch (error) {
    toast.error('Something went wrong, try again');
    console.log("error from getAppointmentById", error);
  }
};

const AppointmentService = {
  getAppointments,
  addAppointment,
  updateAppointment,
  deleteAppointment,
  getAppointmentById,
};

export default AppointmentService;
