import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import VisitorService from "../../services/visitor.service";
import { toast } from "react-toastify";

const Visitors = () => {
  let SrNo = 0;
  const navigate = useNavigate();
  const [visitors, setVisitors] = useState([]);
  const getVisitors = async () => {
    const getVisitorsRes = await VisitorService.getVisitors();
    if (getVisitorsRes.statusCode === 200) {
      setVisitors(getVisitorsRes.data);
    }
  };

  useEffect(() => {
    getVisitors();
  }, [])

  document.title = "List Visitors";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="List Visitors" pageTitle="Visitors" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">List Visitors</h4>
                </CardHeader>

                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Search..."
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="customerTable"
                      >
                        <thead className="table-light">
                          <tr>
                            <th className="sort" data-sort="customer_name">
                              Sr No
                            </th>
                            <th className="sort" data-sort="customer_name">
                              Full Name
                            </th>
                            <th className="sort" data-sort="customer_name">
                              Mobile One
                            </th>
                            <th className="sort" data-sort="customer_name">
                              Mobile Two
                            </th>
                            <th className="sort" data-sort="customer_name">
                              IMEI One
                            </th>
                            <th className="sort" data-sort="customer_name">
                              IMEI Two
                            </th>
                            <th className="sort" data-sort="customer_name">
                              IMSI One
                            </th>
                            <th className="sort" data-sort="customer_name">
                              IMSI Two
                            </th>
                            <th className="sort" data-sort="action">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all">
                          {visitors &&
                            visitors.length > 0 &&
                            visitors.map((visitor, index) => {
                              SrNo += 1;
                              return (
                                <tr key={index}>
                                  <td className="customer_name">{SrNo}</td>
                                  <td className="customer_name">
                                    {visitor.fullName}
                                  </td>
                                  <td className="customer_name">
                                    {visitor.mobileOne}
                                  </td>
                                  <td className="customer_name">
                                    {visitor.mobileTwo}
                                  </td>
                                  <td className="customer_name">
                                    {visitor.amOne}
                                  </td>
                                  <td className="customer_name">
                                    {visitor.amTwo}
                                  </td>
                                  <td className="customer_name">
                                    {visitor.pmOne}
                                  </td>
                                  <td className="customer_name">
                                    {visitor.pmTwo}
                                  </td>
                                  <td>
                                    <div className="d-flex gap-2">
                                      <div className="edit">
                                        <button
                                          className="btn btn-sm btn-success edit-item-btn"
                                          onClick={() => {navigate(`/view-location/${visitor.id}`)}}
                                        >
                                          Get Location
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                      <div className="noresult" style={{ display: "none" }}>
                        <div className="text-center">
                          <lord-icon
                            src="https://cdn.lordicon.com/msoeawqm.json"
                            trigger="loop"
                            colors="primary:#121331,secondary:#08a88a"
                            style={{ width: "75px", height: "75px" }}
                          ></lord-icon>
                          <h5 className="mt-2">Sorry! No Result Found</h5>
                          <p className="text-muted mb-0">
                            We've searched more than 150+ Orders We did not find
                            any orders for you search.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Visitors;
