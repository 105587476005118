import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import DashboardEcommerce from "../pages/DashboardEcommerce";

//Appointment
import Appointments from "../pages/Appointments/Appointments";

//Person To Meet
import PersonToMeet from "../pages/PersonToMeet/PersonToMeet";

//Visitors
import Visitors from "../pages/Visitors/Visitors";
import ViewLocation from "../pages/Visitors/ViewLocation";

//Users
import Users from '../pages/Users/Users';

//APi Key
import APIKey from "../pages/APIKey/index";

//login
import Login from "../pages/Authentication/Login";

const authProtectedRoutes = [
  { path: "/dashboard", component: <DashboardEcommerce /> },
  { path: "/index", component: <DashboardEcommerce /> },

  //Appointments
  { path: "/appointments", component: <Appointments /> },

  //PersonToMeet
  { path: "/person-to-meet", component: <PersonToMeet /> },

  //Visitors
  { path: "/visitors", component: <Visitors /> },
  { path: "/view-location/:visitorId", component: <ViewLocation /> },

  //Users
  { path: "/users", component: <Users /> },

  //Api Key
  { path: "/apps-api-key", component: <APIKey /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/login", component: <Login /> },
];

export { authProtectedRoutes, publicRoutes };