import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { BarChart } from '../../Charts/ChartsJs/ChartsJs';
import AdminService from "../../../services/admin.service";

const Revenue = () => {

  const [chartData, setchartData] = useState([]);

  const getTodaysAppointments = async () => {
    const getTodaysAppointmentsRes = await AdminService.getTodaysAppointments();
    if (getTodaysAppointmentsRes.statusCode === 200) {
      setchartData(getTodaysAppointmentsRes.data);
    }
  };

  useEffect(() => {
    getTodaysAppointments();
  }, []);

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="border-0 align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Today's Appointments</h4>
        </CardHeader>

        <CardBody className="p-0 pb-2">
          <BarChart
            dataColors='["--vz-primary-rgb, 0.8", "--vz-primary-rgb, 0.9"]'
            label={`Today's Appointments`}
            labels={["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"]}
            chartData={chartData}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Revenue;
