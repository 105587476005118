import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import PTMService from "../../services/personToMeet.service";
import { toast } from "react-toastify";

const PersonToMeet = () => {
  let SrNo = 0;
  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };

  const [editModalToggle, setEditModalToggle] = useState(false);
  const tog_edit = () => {
    setEditModalToggle(!editModalToggle);
  };

  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  const [people, setPeople] = useState([]);
  const getPeopleToMeet = async () => {
    const getPeopleToMeetRes = await PTMService.getPersonToMeet();
    if (getPeopleToMeetRes.statusCode === 200) {
      setPeople(getPeopleToMeetRes.data);
    }
  };

  const [newPersonToMeet, setNewPersonToMeet] = useState({
    name: '',
  });
  const addPersonToMeetHandler = async (e) => {
    e.preventDefault();
    const addPersonToMeetRes = await PTMService.addPersonToMeet(newPersonToMeet);
    if (addPersonToMeetRes.statusCode === 200) {
      toast.success('Person added successfully');
      getPeopleToMeet()
    }
    tog_list()
  }

  const [editPerson, setEditPerson] = useState({});
  const [editPersonId, setEditPersonId] = useState({});
  const editClickHandler = async (id) => {
    const getPersonByIdRes = await PTMService.getPersonToMeetById(id);
    if (getPersonByIdRes.statusCode === 200) {
      setEditPersonId(id);
      setEditPerson({
        name: getPersonByIdRes.data.name,
      })
      tog_edit();
    }
  }
  const editPersonHandler = async (e) => {
    e.preventDefault();
    const editPersonRes = await PTMService.updatePersonToMeet(editPersonId, editPerson);
    if (editPersonRes.statusCode === 200) {
      getPeopleToMeet();
      toast.success('Person updated successfully');
    }
    tog_edit()
  }

  const [deletePersonId, setDeletePersonId] = useState();
  const deletePersonHandler = async () => {
    const deletePersonRes = await PTMService.deletePersonToMeet(deletePersonId);
    if (deletePersonRes.statusCode === 200) {
      getPeopleToMeet();
      toast.success('Person deleted successfully');
    }
    tog_delete();
  }

  useEffect(() => {
    getPeopleToMeet();
  }, [])

  document.title = "List People";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="List People" pageTitle="People" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Add, Edit & Remove People</h4>
                </CardHeader>

                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={() => tog_list()}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add
                          </Button>
                        </div>
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Search..."
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="customerTable"
                      >
                        <thead className="table-light">
                          <tr>
                            <th className="sort" data-sort="customer_name">
                              Sr No
                            </th>
                            <th className="sort" data-sort="customer_name">
                              Full Name
                            </th>
                            <th className="sort" data-sort="action">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all">
                          {people &&
                            people.length > 0 &&
                            people.map((person, index) => {
                              SrNo += 1;
                              return (
                                <tr key={index}>
                                  <td className="customer_name">{SrNo}</td>
                                  <td className="customer_name">
                                    {person.name}
                                  </td>
                                  <td>
                                    <div className="d-flex gap-2">
                                      <div className="edit">
                                        <button
                                          className="btn btn-sm btn-success edit-item-btn"
                                          onClick={() => {
                                            editClickHandler(person.id);
                                            }}
                                        >
                                          Edit
                                        </button>
                                      </div>
                                      <div className="remove">
                                        <button
                                          className="btn btn-sm btn-danger remove-item-btn"
                                          onClick={() => {
                                            setDeletePersonId(person.id)
                                            tog_delete()
                                            }}
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                      <div className="noresult" style={{ display: "none" }}>
                        <div className="text-center">
                          <lord-icon
                            src="https://cdn.lordicon.com/msoeawqm.json"
                            trigger="loop"
                            colors="primary:#121331,secondary:#08a88a"
                            style={{ width: "75px", height: "75px" }}
                          ></lord-icon>
                          <h5 className="mt-2">Sorry! No Result Found</h5>
                          <p className="text-muted mb-0">
                            We've searched more than 150+ Orders We did not find
                            any orders for you search.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Add Modal */}
      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            tog_list();
          }}
        >
          {" "}
          Add Person{" "}
        </ModalHeader>
        <form className="tablelist-form" onSubmit={addPersonToMeetHandler}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>

            <div className="mb-3">
              <label htmlFor="customername-field" className="form-label">
                Full Name
              </label>
              <input
                type="text"
                id="customername-field"
                className="form-control"
                placeholder="Enter Full Name"
                required
                onChange={(e) => {
                  setNewPersonToMeet({
                    ...newPersonToMeet,
                    name: e.target.value,
                  })
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                Add
              </button>
              {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Edit Modal */}
      <Modal
        isOpen={editModalToggle}
        toggle={() => {
          tog_edit();
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            tog_edit();
          }}
        >
          {" "}
          Edit Person{" "}
        </ModalHeader>
        <form className="tablelist-form" onSubmit={editPersonHandler}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>

            <div className="mb-3">
              <label htmlFor="customername-field" className="form-label">
                Full Name
              </label>
              <input
                type="text"
                id="customername-field"
                className="form-control"
                placeholder="Enter Full Name"
                required
                defaultValue={editPerson.name}
                onChange={(e) => {
                  setEditPerson({
                    ...editPerson,
                    name: e.target.value,
                  })
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setEditModalToggle(false)}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="add-btn"
              >
                Save
              </button>
              {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            tog_delete();
          }}
        ></ModalHeader>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Person ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={() => deletePersonHandler()}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default PersonToMeet;
